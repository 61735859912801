import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"

import Container from "../components/Container"
import PageHeader from "../components/PageHeader"
import Box from "../components/Box"
import Heading from "../components/Heading"
import Wave from "../components/Wave"
import SeoHelmet from "../components/Seo"

const BlogPostLayout = ({ data }) => {
  const blogPost = data.datoCmsBlog

  return (
    <>
      <SeoHelmet
        title={blogPost.title}
        description={blogPost.intro}
        url={`http://lapidus.se/news/${blogPost.slug}`}
      />
      <PageHeader
        title={blogPost.title}
        subtitle={blogPost.intro}
        kicker={blogPost.date}
      />
      <Box position="relative">
        <Box position="relative" zIndex={2} className="content">
          {blogPost.content.map(c => (
            <Box>
              <Container size="sm" mt="5rem" mb={"2rem"}>
                <Heading fontSize={["md", "xl"]}>{c.title}</Heading>
                <SpecialDiv dangerouslySetInnerHTML={{ __html: c.body }} />
              </Container>
              <Container size="md" mt="1rem">
                <img
                  src={c.image && `${c.image.url}?auto=format`}
                  alt={c.image && c.image.alt}
                  width="100%"
                />
              </Container>
            </Box>
          ))}
          {/* <img
              src={blogPost.cover && blogPost.cover.url}
              alt={blogPost.title}
              width="100%"
            /> */}
        </Box>
        {/*   <Box position="absolute" top="50%" bottom="0" left="0" right="0">
          <Wave color="lightGrey" variant="top2" height="100%" />
        </Box> */}
      </Box>
      <Wave color="white" variant="bottom3" bg="navy" />
    </>
  )
}

const SpecialDiv = styled.div`
  p,
  ul {
    font-size: 1.5rem;
    line-height: 1.5;
  }
`

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    datoCmsBlog(slug: { eq: $slug }) {
      id
      slug
      title
      date(formatString: "MMMM D, YYYY")
      cover {
        url
      }
      content {
        title
        body
        image {
          url
          alt
        }
      }
    }
  }
`

export default BlogPostLayout
